/**
 * @type {firebase.FieldValue}
 */
let _FieldValue

/**
 * @type {firebase.firestore.FieldPath}
 */
// let _FieldPath

/**
 * @type {firebase.firestore.Firestore}
 */
let _firestore

/**
 * @type {firebase.firestore.Firestore.CollectionReference.doc}
 */
let _baseDoc

/**
 * @type {firebase.firestore.Firestore.CollectionReference.doc}
 */
let _adminDoc

/**
 * @typedef IUserModel
 * @property {string} id
 */

/**
 * @interface BaseCollection
 * @template TModel
 */
class BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _firestore.collection('__NONE__')
  }

  /**
   * @returns {FirebaseFirestore.DocumentReference<FirebaseFirestore.DocumentData>}
   */
  docRef(docId) {
    return this.collection.doc(docId)
  }

  /**
   * @returns {string}
   */
  getNewDocId() {
    return this.collection.doc().id
  }

  /**
   * @param {FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData>} doc
   * @returns {TModel}
   */
  getDataFromDocumentData(doc) {
    if (doc.exists) {
      return {
        ...doc.data(),
        id: doc.id
      }
    } else {
      return null
    }
  }

  getAllDataFromDocs(docs) {
    if (docs.length === 0) return null
    return docs.map((d) => this.getDataFromDocumentData(d))
  }

  /**
   * @returns {Promise<TModel[]>}
   */
  all() {
    return this.collection
      .get()
      .then((q) => q.docs.map(this.getDataFromDocumentData))
  }

  /**
   * @returns {Promise<string[]>}
   */
  allIds() {
    return this.collection.get().then((q) => q.docs.map((item) => item.id))
  }

  /**
   * @param {string} id
   * @returns {Promise<TModel>}
   */
  findById(id) {
    return this.collection.doc(id).get().then(this.getDataFromDocumentData)
  }

  /**
   * @param {TModel} obj
   * @returns {Promise<TModel>}
   */
  add(obj) {
    return this.collection
      .add(obj)
      .then((q) => q.get())
      .then(this.getDataFromDocumentData)
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  set(id, obj) {
    return this.collection.doc(id).set(obj)
  }

  /**
   * @param {string} id
   * @param {TModel} obj
   * @returns {Promise<void>}
   */
  update(id, obj) {
    return this.collection.doc(id).update(obj)
  }

  /**
   * @param {string} id
   * @returns {Promise<void>}
   */
  delete(id) {
    return this.collection.doc(id).delete()
  }

  /**
   * Return unsubscribe function
   * @param {string} id
   * @param {(object: TModel) => void} callback
   * @returns {() => void}
   */
  onUpdated(id, callback) {
    return this.collection.doc(id).onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }
}

class OrderCollection extends BaseCollection {
  _companyId

  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('ORDER')
  }

  getOpenedOrder() {
    return this.collection
      .where('status', '==', 'open')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getCheckingOrder() {
    return this.collection
      .where('status', '==', 'checking')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getUncompletedOrder() {
    return this.collection
      .where('status', 'in', ['open', 'matched', 'checking'])
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getRecentCompletedOrder() {
    const now = new Date()
    const lastMonth = new Date(now.setMonth(now.getMonth() - 1))
    return this.collection
      .where('endedAt', '>=', lastMonth)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getDoneOrderByCategoryAndUserId(userId, category) {
    return this.collection
      .where('status', '==', 'done')
      .where('category', '==', category)
      .where('worker', '==', userId)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  async uploadOrderFromCSV(uid, orders, geocoder) {
    const batch = _firestore.batch()

    for (const order of orders) {
      const newOrder = order

      // 緯度・経度に変換
      let lat, lng
      await geocoder.geocode(
        {
          address: order.address
        },
        async (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            lat = results[0].geometry.location.lat()
            lng = results[0].geometry.location.lng()
          }
        }
      )
      if (!lat || !lng) return

      newOrder.lat = lat
      newOrder.lng = lng
      newOrder.createdAt = new Date()
      newOrder.createdBy = uid
      newOrder.lastEditedBy = uid
      if (order.building) {
        newOrder.address = `${order.address} ${order.building}`
        delete newOrder.building
      }
      newOrder.status = 'open'

      const ref = this.collection.doc()
      batch.set(ref, newOrder)
    }
    return await batch
      .commit()
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  async deleteByRef(ref) {
    if (!ref) return
    return await ref.delete()
  }
}

/**
 * @extends {BaseCollection<IUserModel>}
 */

/**
 * @extends {BaseCollection<IUserModel>}
 */
class BusinessUserCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc.collection('BUSINESS_USER')
  }

  getByUserIds(userIds) {
    return this.collection
      .where('uid', 'in', userIds)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

/**
 * @extends {BaseCollection<IUserModel>}
 */
class JoinedCompanyCollection extends BaseCollection {
  _userId

  constructor(_userId) {
    super()
    this._userId = _userId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc
      .collection('BUSINESS_USER')
      .doc(this._userId)
      .collection('JOINED_COMPANY')
  }
}

class BusinessCompanyCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc.collection('BUSINESS_COMPANY')
  }

  getByJoinedCompanyIds(joinedCompanyIds) {
    return this.collection
      .where('companyId', 'in', joinedCompanyIds)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

class BusinessCompanySecretCollection extends BaseCollection {
  _companyId

  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('BUSINESS_COMPANY_SECRET')
  }
}

class BusinessCompanyMemberCollection extends BaseCollection {
  _companyId

  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */

  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('BUSINESS_COMPANY_MEMBER')
  }
}

// スポワーさんのコレクション
class UserCollection extends BaseCollection {
  get collection() {
    return _firestore.collection('USER')
  }
}

class WorkCollection extends BaseCollection {
  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _firestore.collection('WORK')
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {string} param.customer
   * @param {string} param.rounder
   * @param {(objects: IWorkModel[]) => void} callback
   * @returns {() => void}
   */

  onUpdatedWithOptions({ customer, rounder }, callback) {
    let query = this.collection
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()
    const date = now.getDate()
    let beforeMonth
    if (month === 0) {
      beforeMonth = new Date(`${year - 1}/12/${date}`)
    } else {
      beforeMonth = new Date(`${year}/${month}/${date}`)
    }

    if (customer) {
      query = query.where('customer', '==', customer)
    }
    if (rounder) {
      query = query.where('rounder', '==', rounder)
    }
    return query
      .where('createdAt', '>=', beforeMonth)
      .orderBy('createdAt', 'desc')
      .limit(1000)
      .onSnapshot((next) => {
        const obj = this.getAllDataFromDocs(next.docs)
        callback(obj)
      })
  }

  getAllWorksInToday() {
    const now = new Date()
    const todayStart = new Date(
      `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()} 01:00:00`
    )

    return this.collection
      .where('createdAt', '>=', todayStart)
      .where('status', '==', 'done')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getAll24HoursElapsedWorks() {
    const date24HoursAgo = new Date(
      new Date().setHours(new Date().getHours() - 24)
    )

    return this.collection
      .where('createdAt', '<=', date24HoursAgo)
      .where('customer', '==', 'charge_spot')
      .where('status', '==', 'doing')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getAllWorksBefore24HoursLimit20(rounder) {
    const date = new Date()
    date.setDate(date.getDate() - 1)

    return this.collection
      .where('rounder', '==', rounder)
      .where('createdAt', '>=', date)
      .orderBy('createdAt', 'desc')
      .limit(20)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getAllWorksInPeriod(type, firstDate, lastDate) {
    return this.collection
      .where(type, '>=', firstDate)
      .where(type, '<', lastDate)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getAllExportWorksInEndedAtPeriod(firstDate, lastDate) {
    return this.collection
      .where('endedAt', '>=', firstDate)
      .where('endedAt', '<', lastDate)
      .where('currentWork', '==', 'goingToGoalSpot')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getAllWorksByEndedAt(date) {
    return this.collection
      .where('endedAt', '>=', date)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getAllUserWorksInEndedAtPeriod(uid, firstDate, lastDate) {
    return this.collection
      .where('endedAt', '>=', firstDate)
      .where('endedAt', '<', lastDate)
      .where('rounder', '==', uid)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getAllToMonth(uid) {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    const firstDate = new Date(`${year}/${month + 1}/1`)
    let lastDate
    if (month === 11) {
      lastDate = new Date(`${year + 1}/1/1`)
    } else {
      lastDate = new Date(`${year}/${month + 2}/1`)
    }

    return this.collection
      .where('createdAt', '>=', firstDate)
      .where('createdAt', '<', lastDate)
      .where('rounder', '==', uid)
      .orderBy('createdAt', 'desc')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  findKaketsukeWork(customer, orderId) {
    return this.collection
      .where('customer', '==', customer)
      .where('orderId', '==', orderId)
      .get()
      .then((q) => {
        const data = this.getAllDataFromDocs(q.docs)
        if (data) {
          return data[0]
        } else {
          return null
        }
      })
  }
}

class FormatCollection extends BaseCollection {
  _companyId

  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('FORMAT')
  }

  getActiveFormats() {
    return this.collection
      .where('status', '==', 'active')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

class InvitationCollection extends BaseCollection {
  get collection() {
    return _baseDoc.collection('BUSINESS_INVITATION')
  }

  getFromCompany(companyId) {
    return this.collection
      .where('companyId', '==', companyId)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getFromEmail(email) {
    return this.collection
      .where('email', '==', email)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

class InvoieLogCollection extends BaseCollection {
  /**
   * @type {string}
   * @memberof InvoieLogCollection
   *
   */
  _companyId

  /**
   * @param {string} companyId
   * @constructor
   */
  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('INVOICE_LOG')
  }

  getLogsByYearMonth(year, month) {
    const timeStart = new Date(year, month - 1, 1)
    const timeEnd = new Date(year, month, 1)
    return this.collection
      .where('createdAt', '>=', timeStart)
      .where('createdAt', '<', timeEnd)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

class InvoieSummaryCollection extends BaseCollection {
  /**
   * @type {string}
   * @memberof InvoieSummaryCollection
   *
   */
  _companyId

  /**
   * @param {string} companyId
   * @constructor
   */
  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('INVOICE_SUMMARY')
  }
}

class MessageRoomCollection extends BaseCollection {
  /**
   * @param {string} companyId
   */
  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('MESSAGE_ROOM')
  }

  getOpenRooms() {
    return this.collection
      .where('status', '==', 'open')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  /**
   * Return unsubscribe function
   * @param {Object} param
   * @param {(objects: IMessageRoomModel[]) => void} callback
   * @returns {() => void}
   */
  getMyMessageRoomSnap(callback) {
    const query = this.collection
      .where('companyId', '==', this._companyId)
      .where('status', '==', 'open')
    return query.onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }
}

class MessageCollection extends BaseCollection {
  /**
   * @param {string} companyId
   * @param {string} roomId
   */
  constructor(companyId, roomId) {
    super()
    this._companyId = companyId
    this._roomId = roomId
  }

  /**
   * @returns {firebase.firestore.CollectionReference}
   */
  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('MESSAGE_ROOM')
      .doc(this._roomId)
      .collection('MESSAGE')
  }

  onUpdatedWithOptions(callback) {
    return this.collection.orderBy('createdAt').onSnapshot((next) => {
      const obj = this.getAllDataFromDocs(next.docs)
      callback(obj)
    })
  }

  getCreatedAtAscAll() {
    return this.collection
      .orderBy('createdAt', 'asc')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }

  getRecentMessage() {
    return this.collection
      .orderBy('createdAt', 'asc')
      .limit(1)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

class joinedMessageRoomCollection extends BaseCollection {
  _companyId

  constructor(companyId) {
    super()
    this._companyId = companyId
  }

  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('JOINED_MESSAGE_ROOM')
  }

  getByOrderId(orderId) {
    return this.collection
      .where('orderId', '==', orderId)
      .get()
      .then((q) => {
        const rooms = this.getAllDataFromDocs(q.docs)
        if (rooms && rooms.length > 0) {
          return rooms[0]
        } else {
          return null
        }
      })
  }

  getByRoomIds(roomIds) {
    return this.collection
      .where('messageRoomId', 'in', roomIds)
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

class AlreadyReadCollection extends BaseCollection {
  /**
   * @param {string} _companyId
   * @param {string} _roomId
   */

  constructor(companyId, roomId) {
    super()
    this._companyId = companyId
    this._roomId = roomId
  }

  get collection() {
    return _baseDoc
      .collection('BUSINESS_COMPANY')
      .doc(this._companyId)
      .collection('MESSAGE_ROOM')
      .doc(this._roomId)
      .collection('ALREADY_READ')
  }
}

/**
 * The whole database
 */
const businessDB = {
  businessUserCollection: () => new BusinessUserCollection(),
  businessCompanyCollection: () => new BusinessCompanyCollection(),
  businessCompanyMemberCollection: (companyId) =>
    new BusinessCompanyMemberCollection(companyId),
  businessCompanySecretCollection: (companyId) =>
    new BusinessCompanySecretCollection(companyId),
  orderCollection: (companyId) => new OrderCollection(companyId),
  formatCollection: (companyId) => new FormatCollection(companyId),
  joinedCompanyCollection: (userId) => new JoinedCompanyCollection(userId),
  invitationCollection: () => new InvitationCollection(),
  messageRoomCollection: (companyId) => new MessageRoomCollection(companyId),
  messageCollection: (companyId, roomId) =>
    new MessageCollection(companyId, roomId),
  joinedMessageRoomCollection: (companyId) =>
    new joinedMessageRoomCollection(companyId),
  alreadyReadCollection: (companyId, roomId) =>
    new AlreadyReadCollection(companyId, roomId),
  InvoiceLogCollection: (companyId) => new InvoieLogCollection(companyId),
  InvoiceSummaryCollection: (companyId) =>
    new InvoieSummaryCollection(companyId)
}

const database = {
  userCollection: () => new UserCollection(),
  workCollection: () => new WorkCollection(),
  increment: (number) => increment(number),
  runTransaction: async (callback) => await runTransaction(callback),
  deleteField: () => _FieldValue.delete()
}

class DefaultFormatCollection extends BaseCollection {
  get collection() {
    return _adminDoc.collection('FORMAT')
  }

  getActiveFormats() {
    return this.collection
      .where('status', '==', 'active')
      .get()
      .then((q) => this.getAllDataFromDocs(q.docs))
  }
}

const adminDB = {
  formatCollection: () => new DefaultFormatCollection()
}

/**
 * @param {firebase.firestore.Firestore} firestore
 * @param {Object} param1
 * @param {firebase.firestore.FieldValue} param1.FieldValue
 */
const initializeDatabase = (firestore, { FieldValue }) => {
  _FieldValue = FieldValue
  _firestore = firestore
  // _FieldPath = FieldPath
  _baseDoc = firestore.collection('SERVICE').doc('business')
  _adminDoc = firestore.collection('SERVICE').doc('spotwork-admin')
}

module.exports = {
  database,
  businessDB,
  adminDB,
  initializeDatabase
}
